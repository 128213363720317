import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia'
import vuetify from './plugins/vuetify';
import { TimePicker  } from 'ant-design-vue';
import * as Sentry from "@sentry/vue";

import 'mapbox-gl/dist/mapbox-gl.css';
import { createApp } from 'vue';

const pinia = createPinia()

// Vue.config.productionTip = false;
const app = createApp(App)

// Only load sentry in production
if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "training_prod") {
  console.log('Active sentry')
  Sentry.init({
    dsn: "https://f10833dfefb04869addccbff13e2d2fb@o313456.ingest.sentry.io/5560193",
    autoSessionTracking: true,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}



app.use(pinia)
app.use(router)
app.use(vuetify)
app.use(TimePicker)

app.mount('#app')