<script setup lang="ts">
import NavBar from "@/components/Layout/NavBar.vue";
import { useInterfaceStore } from "./store/interface";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { isTraining, isDev } from "./firebase";
const interfaceStore = useInterfaceStore();

const route = useRoute();

const showMenu = computed(() => {
  return (
    route.name !== "login" &&
    route.path !== "/" &&
    route.name !== "welcome" &&
    route.name !== "resetpassword" &&
    route.name !== "pagenotfound" &&
    route.name !== "accountdeletion" &&
    route.name !== "applink"
  );
})

const Messages = computed(() => {
  return interfaceStore.messages;
});

const showSnackbar = ref(false);

watch(
  Messages,
  (val) => {
    if (val.length > 0) {
      showSnackbar.value = true;
    }
    if (process.env.NODE_ENV === "development") {
      console.log('Interface Store: ' + JSON.stringify(val));
    }
  },
  { deep: true }
)

const loading = computed(() => {
  return interfaceStore.loading;
});
</script>

<template>
  <v-app>
    <v-chip v-if="isTraining" class="training-chip" color="orange" variant="elevated" dark small>
      TRAINING {{ isDev ? '| Staging' : '' }}
    </v-chip>
    <NavBar  v-if="showMenu" />
    <v-main>
      <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
      <router-view />
      <v-snackbar v-for="message in Messages" :key="message.id" :color="message.color" v-model="showSnackbar" right>
        {{ message.message }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
.v-application--wrap {
  min-height: calc(100vh - 64px);
}

.training-chip {
  position: absolute;
  // Center the chip horizontally
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 9999;
  margin: 2px;
  margin-top: 15px;
  font-weight: 700;
}
</style>

<style>
@media print {
  .no-print {
    display: none;
    
  }

  .print-only {
    display: block;
  }


  .print-width-check {
    background-color: transparent !important;
    color: #757575 !important;
  }

  .print-remove-background {
    background-color: transparent !important;
  }

  .print-remove-remove-spacing {
    margin: 0 !important;
    padding: 0 !important;
  }


  .page-break {
    page-break-before: always;
  }

  .print-text-black,
  .print-text-black * {
    color: black !important;
  }
}

</style>
